import useSWR from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'

export interface CatalogueProductInterface {
  airtableId: string
  colours: { name: string; hex: string }[]
  frames: { name: string; swatchImage: string }[]
  global: boolean
  image: string
  pricing: Record<string, { source: 'fixed' | 'converted'; value: number } | undefined>
  manufacturingRegions: string[]
  name: string
  sizes: string | null
  slug: string
}

export interface CatalogueCategoryInterface {
  airtableId: string
  fullSlug: string
  images: string[]
  name: string
  products: Record<string, CatalogueProductInterface | undefined>
  slug: string
  subCategories: Record<string, CatalogueCategoryInterface | undefined>
}

interface ProductApiErrorResponseInterface {
  statusText: string
  statusCode: number
  data: unknown
  traceParent: string
  message?: string
}

export function useProductCatalogue() {
  const { data, error } = useSWR<
    Record<string, CatalogueCategoryInterface | undefined>,
    FetchErrorInterface<ProductApiErrorResponseInterface>
  >(`${process.env.REACT_APP_PRODUCT_API}/catalogue`, fetcher)

  return {
    productCatalogue: data,
    isLoading: !data && !error,
    error
  }
}
