export const ROUTE_PATHS = {
  CREATE_ORDER: '/orders/create/select-country',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  MOF: { SEARCH: '/orders/create/select-item', BASKET: '/orders/create/add-image' },
  ORDERS: '/orders',
  BULK_UPLOAD: '/orders/bulk-upload',
  ORDER_DETAIL: (orderId: string) => `/orders/${orderId}/detail`,
  PASSWORD_RESET: '/password-reset',
  PAYMENT_AUTHORISATION: `/payment-authorisation`,
  PRODUCT_INFO: '/product-info',
  PRODUCTS: '/product-info/products',
  PRODUCT_CATALOGUE: '/product-catalogue',
  MAINTAIN_SALES_CHANNEL_CONNECTIONS: '/maintain-sales-channel-connections',
  SALES_CHANNELS: {
    INDEX: '/sales-channels',
    CONFIGURE: (salesChannelId: string) => `/sales-channels/${salesChannelId}`,
    CONNECT_FROM_REDIRECT: '/connect-sales-channel'
  },
  SETTINGS: {
    INDEX: '/settings',
    ACCOUNT: '/settings/account',
    BILLING: '/settings/billing',
    INTEGRATIONS: '/settings/integrations',
    API: '/settings/api',
    PREFERENCES: '/settings/preferences'
  },
  IMAGE_LIBRARY: {
    INDEX: '/image-library'
  },
  IMAGE_DETAIL: (imageId: string) => `/image-library/${imageId}`
}
