// @flow

import type { Element } from 'react'
import type {
  V4ProductDetails,
  CatalogueItem,
  Quote,
  ShipmentQuote,
  QuoteResponseItem,
  Status,
  Dictionary,
  MultiAssetBasketItem,
  ThumbnailItem,
  MultiAssetTemplate,
  MultiAssetTemplates
} from '../../../../types'

import React, { PureComponent } from 'react'

import ProductionCountries from '../../components/ProductionCountries'
import NumberField from '../../../../components/inputs/NumberField'
import styles from './SummaryItemList.module.css'
import getDisplayImage from '../../../../helpers/getDisplayImage'
import { ERROR, LOADING, SUCCESS } from '../../../../data/rsaa'
import { buildTemplateId } from '../../../../actions/images'
import { createThumbnailItemFromBasketItem } from '../../../../helpers/createThumbnailItemFromBasketItem'
import { formatShipmentDescription } from '../../../../helpers/formatShipmentDescription'
import { removeDuplicates } from '../../../../helpers/array'
import { getCurrencySymbol } from '../../../../helpers/formatCost'
import { formatCost } from '../../../../helpers/charge/formatCost'
import cx from 'classnames'
import PrintAreaSwitcher from '../../../../components/PrintAreaSwitcher'
import LoadingEllipsis from '../../../../components/LoadingEllipsis'
import { isDefaultArtworkRequired } from '../../../../selectors/manualOrderForm'
import { entries } from '../../../../helpers/dictionary'

// $FlowFixMe: TypeScript file
import ImageEditorPreview from '../../../../v2/components/ImageEditor/components/ImageEditorPreview.component'
// $FlowFixMe: TypeScript file
import ImageLibraryFileTypePill from '../../../../v2/components/ImageLibraryFileTypePill'
// $FlowFixMe: TypeScript file
import { AttributesDisplay } from './AttributesDisplay.component'

type Props = {|
  quote: Quote,
  basketItems: MultiAssetBasketItem[],
  imageStatus: Dictionary<Status>,
  templateStatus: Dictionary<Status>,
  catalogueItems: Dictionary<CatalogueItem>,
  v4ProductDetails: Dictionary<V4ProductDetails>,
  isLoadingCatalogueData: boolean,
  templates: Dictionary<MultiAssetTemplates>,
  updatePriceToUser: (priceToUserAsInt: number, basketItemId: number) => void,
  changeSelectedPrintAreaForBasketItem: (basketItemId: string, selectedPrintArea: string) => void
|}

type ImageLoadingProgress = {|
  isOverlayLoading: boolean,
  isArtworkLoading: boolean,
  hasArtworkRenderError: boolean,
  hasOverlayError: boolean,
  hasUnderlayError: boolean
|}

type State = {|
  hasImageError: boolean,
  previewImageLoadingProgress: Dictionary<ImageLoadingProgress>
|}

export default class SummaryItemListV4 extends PureComponent<Props, State> {
  state: State = {
    previewImageLoadingProgress: this.initPreviewImageLoadingProgress(),
    hasImageError: false
  }

  initPreviewImageLoadingProgress(): Dictionary<ImageLoadingProgress> {
    return this.props.basketItems.reduce((imageProgressAcc, basketItem) => {
      imageProgressAcc[basketItem.id.toString()] = {
        isOverlayLoading: true,
        isArtworkLoading: true,
        hasArtworkRenderError: false,
        hasOverlayError: false,
        hasUnderlayError: false
      }
      return imageProgressAcc
    }, {})
  }

  onImageError: () => void = () => this.setState({ hasImageError: true })

  onChangePriceToUser: (event: SyntheticEvent<any>, basketItem: MultiAssetBasketItem) => void = (
    event: SyntheticEvent<*>,
    basketItem: MultiAssetBasketItem
  ) => {
    const value = event.currentTarget.value
    const valueAsNumber = event.currentTarget.valueAsNumber

    if (value === '' || valueAsNumber < 0) {
      this.props.updatePriceToUser(0, basketItem.id)
    } else {
      const priceToUserAsInt = Math.round(Number(value) * 100)
      this.props.updatePriceToUser(priceToUserAsInt, basketItem.id)
    }
  }

  getPriceToUserAsFloat: (basketItem: MultiAssetBasketItem) => number = (basketItem: MultiAssetBasketItem): number => {
    const value = basketItem.priceToUserAsInt / 100

    return !isNaN(value) ? value : 0
  }

  getTemplateForItem: (item: MultiAssetBasketItem, templateId: string) => ?MultiAssetTemplate = (
    item: MultiAssetBasketItem,
    templateId: string
  ): ?MultiAssetTemplate => {
    const { templates } = this.props
    const itemTemplates = templates[templateId]
    if (!item.printAreas || !item.selectedPrintArea) {
      return null
    }
    const artworkTransformations = item.printAreas[item.selectedPrintArea]?.artworkTransformations

    if (!artworkTransformations || !artworkTransformations.orientation || !itemTemplates) {
      return null
    }

    const templateOrientation = artworkTransformations.orientation
    return itemTemplates.printAreas[item.selectedPrintArea].orientations[templateOrientation]
  }

  thumbnailItem: (item: MultiAssetBasketItem, templateId: string) => ?ThumbnailItem = (
    item: MultiAssetBasketItem,
    templateId: string
  ): ?ThumbnailItem => {
    if (!item.printAreas || !item.selectedPrintArea) {
      return null
    }
    return createThumbnailItemFromBasketItem(
      item,
      item.printAreas[item.selectedPrintArea],
      this.getTemplateForItem(item, templateId)
    )
  }

  selectAllPossibleProductionCountries: (shipment: ShipmentQuote) => Array<string> = (
    shipment: ShipmentQuote
  ): string[] => {
    const basketItems = this.selectBasketItems(shipment)
    const { catalogueItems } = this.props

    const allProductionCountries = basketItems.reduce((productionCountryAcc, basketItem) => {
      const catalogueItem = catalogueItems[basketItem.sku]
      return productionCountryAcc.concat(catalogueItem.productionCountries)
    }, [])

    return removeDuplicates(allProductionCountries)
  }

  selectBasketItems: (shipment: ShipmentQuote) => Array<MultiAssetBasketItem> = (
    shipment: ShipmentQuote
  ): MultiAssetBasketItem[] => {
    const shipmentItemIds = shipment.items
    const shipmentItemSkus = this.props.quote.items
      .filter((quoteItem) => shipmentItemIds.includes(quoteItem.id))
      .map((quoteItem) => quoteItem.sku.toUpperCase())

    return this.props.basketItems.filter((item) => {
      return shipmentItemSkus.includes(item.sku)
    })
  }

  selectBasketAndShipmentItems: (shipment: ShipmentQuote) => Array<[MultiAssetBasketItem, QuoteResponseItem]> = (
    shipment: ShipmentQuote
  ): Array<[MultiAssetBasketItem, QuoteResponseItem]> => {
    const shipmentItems = this.props.quote.items
    return this.selectBasketItems(shipment).map((basketItem) => {
      const shipmentItem = this.selectShipmentItemByBasketItem(shipmentItems, basketItem)

      if (!shipmentItem) {
        throw Error('Shipment item cannot be null.')
      }

      return [basketItem, shipmentItem]
    })
  }

  selectShipmentItemByBasketItem: (
    shipmentItems: Array<QuoteResponseItem>,
    basketItem: MultiAssetBasketItem
  ) => ?QuoteResponseItem = (
    shipmentItems: QuoteResponseItem[],
    basketItem: MultiAssetBasketItem
  ): ?QuoteResponseItem => {
    return shipmentItems.find((shipmentItem) => shipmentItem.sku.toUpperCase() === basketItem.sku.toUpperCase())
  }

  get previewLoadingIndicator(): Element<*> {
    return (
      <div className={styles.loadingPreview}>
        Loading preview
        <LoadingEllipsis className={styles.loadingEllipsis} />
      </div>
    )
  }

  onPrintAreaChange: (basketItemId: string, printAreaName: string) => void = (
    basketItemId: string,
    printAreaName: string
  ) => {
    this.setState({
      previewImageLoadingProgress: {
        ...this.state.previewImageLoadingProgress,
        [basketItemId]: {
          ...this.state.previewImageLoadingProgress[basketItemId],
          isOverlayLoading: true,
          isArtworkLoading: true,
          hasArtworkRenderError: false,
          hasOverlayError: false,
          hasUnderlayError: false
        }
      }
    })
    this.props.changeSelectedPrintAreaForBasketItem(basketItemId, printAreaName)
  }

  renderThumbnail: (basketItem: MultiAssetBasketItem, templateId: string) => ?React$Element<*> = (
    basketItem: MultiAssetBasketItem,
    templateId: string
  ): ?Element<*> => {
    const thumbnailItem = this.thumbnailItem(basketItem, templateId)
    const itemTemplateStatus = this.props.templateStatus[templateId]
    const catalogueItem = this.props.catalogueItems[basketItem.sku]

    const basketItemId = basketItem.id.toString()
    const itemImageStatus = this.props.imageStatus[basketItemId]
    const imageEditorKey = [
      'summary-item-image',
      basketItemId,
      itemTemplateStatus,
      itemImageStatus,
      basketItem.selectedPrintArea
    ].join('-')
    const isLoadingPreview = this.isLoadingPreview(basketItem, templateId)
    const printAreaEntriesWithArtwork = basketItem.printAreas
      ? entries(basketItem.printAreas).filter(([printAreaName, printAreaArtworkData]) =>
          Boolean(printAreaArtworkData.artwork)
        )
      : []

    return (
      <React.Fragment>
        <div className={styles.imagePreviewContainer} data-test="image-preview-container">
          {thumbnailItem?.artwork?.mimeType === 'application/pdf' ? (
            <div className="tailwind">
              <div className="flex flex-col items-center">
                <img className="h-[120px] w-[120px] object-contain" src={thumbnailItem?.artwork?.mediumImageUrl} />
              </div>
            </div>
          ) : (
            <>
              {isLoadingPreview && this.previewLoadingIndicator}
              {itemTemplateStatus !== ERROR && !this.state.hasImageError && thumbnailItem && (
                <div
                  className={cx({
                    [styles.visible]: !isLoadingPreview,
                    [styles.hidden]: isLoadingPreview
                  })}
                >
                  <ImageEditorPreview
                    key={imageEditorKey}
                    isPreviewOnly={true}
                    cropRectangle={thumbnailItem.cropRectangle}
                    transformations={thumbnailItem.transformations}
                    printDimensionsInPx={thumbnailItem.printDimensionsInPx}
                    artworkDimensionsInPx={thumbnailItem.artworkDimensionsInPx}
                    imageUrls={thumbnailItem.imageUrls}
                    printDpi={thumbnailItem.printDpi}
                    onLoadArtwork={() => this.onLoadArtwork(basketItemId)}
                    onLoadOverlay={() => this.onLoadOverlay(basketItemId)}
                    onArtworkError={() => this.onArtworkRenderError(basketItemId)}
                    onOverlayError={() => this.onOverlayError(basketItemId)}
                    onUnderlayError={() => this.onUnderlayError(basketItemId)}
                  />
                </div>
              )}
            </>
          )}

          <div className="tailwind">
            <ImageLibraryFileTypePill className="my-4 mx-auto block" fileType={thumbnailItem?.artwork?.fileType} />
          </div>

          {basketItem.selectedPrintArea && printAreaEntriesWithArtwork && printAreaEntriesWithArtwork.length > 1 && (
            <div className={styles.printAreaSwitcherContainer}>
              <PrintAreaSwitcher
                itemCategory={catalogueItem.category}
                selectedPrintArea={basketItem.selectedPrintArea}
                printAreaEntries={printAreaEntriesWithArtwork}
                onPrintAreaChange={(printAreaName) => this.onPrintAreaChange(basketItemId, printAreaName)}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }

  templateStatus: (item: MultiAssetBasketItem, templateId: string) => Status = (
    item: MultiAssetBasketItem,
    templateId: string
  ): Status => {
    if (this.getTemplateForItem(item, templateId)) {
      return SUCCESS
    } else {
      return this.props.templateStatus[templateId]
    }
  }

  isLoadingPreview(item: MultiAssetBasketItem, templateId: string): boolean {
    const basketItemId = item.id.toString()
    const itemImageStatus = this.props.imageStatus[basketItemId]
    const itemTemplateStatus = this.templateStatus(item, templateId)
    const previewLoadingProgress = this.state.previewImageLoadingProgress[basketItemId]

    const arePreviewImagesLoading = Boolean(
      previewLoadingProgress && (previewLoadingProgress.isOverlayLoading || previewLoadingProgress.isArtworkLoading)
    )

    return Boolean(
      !this.hasImagePreviewError(basketItemId, itemTemplateStatus) &&
        (arePreviewImagesLoading || itemImageStatus === LOADING || itemTemplateStatus === LOADING)
    )
  }

  hasImagePreviewError: (basketItemId: string, itemTemplateStatus: Status) => boolean = (
    basketItemId: string,
    itemTemplateStatus: Status
  ): boolean => {
    const previewLoadingProgress = this.state.previewImageLoadingProgress[basketItemId]

    return Boolean(
      (previewLoadingProgress &&
        (previewLoadingProgress.hasArtworkRenderError ||
          previewLoadingProgress.hasUnderlayError ||
          previewLoadingProgress.hasOverlayError)) ||
        itemTemplateStatus === ERROR
    )
  }

  onOverlayError: (basketItemId: string) => void = (basketItemId: string) =>
    this.setState((state) => ({
      previewImageLoadingProgress: {
        ...state.previewImageLoadingProgress,
        [basketItemId]: {
          ...state.previewImageLoadingProgress[basketItemId],
          hasOverlayError: true
        }
      }
    }))

  onUnderlayError: (basketItemId: string) => void = (basketItemId: string) =>
    this.setState((state) => ({
      previewImageLoadingProgress: {
        ...state.previewImageLoadingProgress,
        [basketItemId]: {
          ...state.previewImageLoadingProgress[basketItemId],
          hasUnderlayError: true
        }
      }
    }))

  onArtworkRenderError: (basketItemId: string) => void = (basketItemId: string) =>
    this.setState((state) => ({
      previewImageLoadingProgress: {
        ...state.previewImageLoadingProgress,
        [basketItemId]: {
          ...state.previewImageLoadingProgress[basketItemId],
          isArtworkLoading: false,
          hasArtworkRenderError: true
        }
      }
    }))

  onLoadOverlay: (basketItemId: string) => void = (basketItemId: string) =>
    this.setState((state) => ({
      previewImageLoadingProgress: {
        ...state.previewImageLoadingProgress,
        [basketItemId]: {
          ...state.previewImageLoadingProgress[basketItemId],
          isOverlayLoading: false
        }
      }
    }))

  onLoadArtwork: (basketItemId: string) => void = (basketItemId: string) =>
    this.setState((state) => ({
      previewImageLoadingProgress: {
        ...state.previewImageLoadingProgress,
        [basketItemId]: {
          ...state.previewImageLoadingProgress[basketItemId],
          isArtworkLoading: false,
          hasArtworkRenderError: false
        }
      }
    }))

  getPreviewImage: (item: MultiAssetBasketItem) => ?React$Element<*> = (item: MultiAssetBasketItem) => {
    const catalogueItem = this.props.catalogueItems[item.sku]
    const colour = item.selectedAttributes.frameColour
    const templateId = buildTemplateId(item.sku, item.selectedAttributes)
    const itemTemplateStatus = this.props.templateStatus[templateId]
    const previewNotAvailable = <div className={styles.previewNotAvailable}>Preview not available</div>

    if (this.hasImagePreviewError(item.id.toString(), itemTemplateStatus)) {
      return previewNotAvailable
    } else if (this.props.isLoadingCatalogueData) {
      return this.previewLoadingIndicator
    } else if (catalogueItem && isDefaultArtworkRequired(catalogueItem.productType)) {
      return (
        <div className={styles.noImagePreview}>
          <img
            src={getDisplayImage(colour, catalogueItem.productType, item.sku)}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            alt="product"
          />
        </div>
      )
    } else if (catalogueItem && !isDefaultArtworkRequired(catalogueItem.productType)) {
      return this.renderThumbnail(item, templateId)
    } else {
      return previewNotAvailable
    }
  }

  renderListItem: (item: MultiAssetBasketItem, shipmentItem: QuoteResponseItem, index: number) => Element<'li'> = (
    item: MultiAssetBasketItem,
    shipmentItem: QuoteResponseItem,
    index: number
  ) => {
    const catalogueItem = this.props.catalogueItems[item.sku]
    return (
      <li key={item.id} className={styles.item} data-test={`order-summary-item-${index}-container`}>
        {this.getPreviewImage(item)}
        <div className={styles.body}>
          <p className={styles.title}>{catalogueItem.description}</p>
          <table data-test="attributes-table">
            <tbody>
              {item.selectedAttributes && (
                <AttributesDisplay
                  selectedAttributes={item.selectedAttributes}
                  sku={item.sku}
                  category={catalogueItem.category}
                />
              )}

              <tr>
                <td className={styles.attributeTitle}>SKU:</td>
                <td className={styles.meta}>{item.sku}</td>
              </tr>
              <tr>
                <td className={styles.attributeTitle}>Price:</td>
                <td className={styles.meta}>{formatCost(shipmentItem.unitCost)}</td>
              </tr>
              <tr>
                <td className={styles.attributeTitle}>Quantity:</td>
                <td className={styles.meta} data-test="quantity-value">
                  {item.quantity}
                </td>
              </tr>
              <tr>
                <td className={styles.attributeTitle}>Item value:</td>
                <td className={styles.meta}>
                  <span className={styles.currencySymbol}>{getCurrencySymbol(shipmentItem.unitCost.currency)}</span>
                  <NumberField
                    onChange={(e) => this.onChangePriceToUser(e, item)}
                    id={`customs-value-item-${item.id}`}
                    step="0.01"
                    min="0"
                    value={this.getPriceToUserAsFloat(item)}
                    className={styles.customsValue}
                    dataTest={`item-value-${index}`}
                  />
                  <span className={styles.customsExplanation}>For customs use only</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    )
  }

  render(): React$Node {
    return (
      <div className={styles.shipmentList}>
        {this.props.quote.shipments.map((shipment, id) => {
          const allProductionCountries = this.selectAllPossibleProductionCountries(shipment)

          return (
            <div key={id} data-test={`summary-shipment-${id}-container`}>
              <div className={styles.shipmentHeader}>
                <h2 className={styles.heading}>
                  Shipment {id + 1} - {formatShipmentDescription(shipment.carrier.service)}
                </h2>
                <ProductionCountries
                  productionCountries={allProductionCountries}
                  productionCountryCode={shipment.fulfillmentLocation.countryCode}
                  showOnSummaryPage={true}
                />
              </div>
              <ul className={styles.itemList}>
                {this.selectBasketAndShipmentItems(shipment).map(([basketItem, shipmentItem]) => {
                  return this.renderListItem(basketItem, shipmentItem, id)
                })}
              </ul>
            </div>
          )
        })}
      </div>
    )
  }
}
