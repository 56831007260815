import { motion } from 'framer-motion'

import { useMerchantService, useSplitToggle } from '../../hooks'
import { SalesChannelPlatformEnum } from '../../enums'
import { ActiveSalesChannelList, SalesChannelsHeader } from './components'
import { ConnectNewSalesChannel } from './components/ConnectNewSalesChannel.component'
import { FEATURE_NAMES } from '../../../split-io/feature-names'

export default function SalesChannels() {
  const { merchantDetails } = useMerchantService()
  const salesChannels = merchantDetails?.salesChannels

  // TODO: remove split, option logic and footer logic
  const { splitIsOn: shopifyIsAvailable, splitIsLoading: shopifyIsLoading } = useSplitToggle({
    toggle: FEATURE_NAMES.SALES_CHANNEL_SHOPIFY
  })

  let externalActiveSalesChannels =
    salesChannels?.filter((salesChannel) => salesChannel.platform !== SalesChannelPlatformEnum.Prodigi) || []

  const showShopifySalesChannel = !shopifyIsLoading && shopifyIsAvailable

  if (!showShopifySalesChannel) {
    externalActiveSalesChannels =
      externalActiveSalesChannels.filter(
        (salesChannel) => salesChannel.platform !== SalesChannelPlatformEnum.Shopify
      ) || []
  }

  return (
    <motion.div animate={{ opacity: 1 }} className="container mx-auto flex flex-col" initial={{ opacity: 0 }}>
      <SalesChannelsHeader />
      {externalActiveSalesChannels?.length > 0 && (
        <ActiveSalesChannelList className="mt-16" activeChannels={externalActiveSalesChannels} />
      )}
      <ConnectNewSalesChannel className="mt-16" />
    </motion.div>
  )
}
