import clsx from 'clsx'
import { ReactNode } from 'react'
export function SettingsPanel({
  id,
  children,
  className = '',
  subtitle,
  title
}: {
  id?: string
  children?: ReactNode
  className?: string
  title?: ReactNode
  subtitle?: ReactNode
}) {
  return (
    <div
      id={id}
      className={clsx(
        className,
        'grid grid-cols-1 gap-y-12 bg-white px-12 py-16 md:gap-x-8 lg:grid-cols-12 lg:gap-y-0'
      )}
    >
      <div className="col-span-4 flex flex-col md:col-span-4 lg:col-span-4">
        {title && title}
        {subtitle && subtitle}
      </div>
      <div className="lg:col-span-full lg:col-start-6 xl:col-span-6 xl:col-start-5">{children}</div>
    </div>
  )
}
