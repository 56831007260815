import React from 'react'
import { Remark } from 'react-remark'
import { Link } from 'react-router-dom'
import { ClockIcon, GlobeIcon } from '@heroicons/react/outline'

import { ProductBreadcrumbs } from '.'
import { formatCost } from '../../../helpers'
import ImageCarousel from '../../ImageCarousel'
import { Variants } from './Variants.component'
import { ROUTE_PATHS } from '../../../constants'
import { ProductDetailTabs } from './ProductDetailTabs.component'
import { ProductCatalogueProductInterface, useUser } from '../../../hooks'

export function ProductDetailsLoaded({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <>
      <div className="gap-3 text-black lg:grid lg:grid-cols-3 lg:gap-5">
        <div className="lg:col-span-2">
          <ProductBreadcrumbs productData={productData} />

          <ProductDescription productData={productData} />
        </div>

        <div className="lg:col-span-1">
          <div className="grid place-content-center lg:-mt-10">
            {productData.images.length > 1 ? (
              <div className="mx-auto max-w-[300px] lg:max-w-[400px]">
                <ImageCarousel
                  className="h-[300px] lg:h-[400px]"
                  images={productData.images}
                  options={{ loop: true }}
                />
              </div>
            ) : (
              <img
                className="h-[300px] w-[300px] max-w-full object-contain object-center lg:h-[400px] lg:w-[400px]"
                alt={productData.name}
                src={productData.heroImage}
              />
            )}
          </div>
        </div>
      </div>

      <Variants productData={productData} />

      <ProductDetailTabs productData={productData} />
    </>
  )
}

function ProductDescription({ productData }: { productData: ProductCatalogueProductInterface }) {
  const { user } = useUser()

  const selectedCurrecy = user?.company.currencyCode.toLowerCase() ?? Object.keys(productData.pricing)[0].toLowerCase()
  const defaultCurrency =
    Object.entries(productData.pricing).find(([, pricingData]) => pricingData?.source === 'fixed')?.[0] ??
    Object.keys(productData.pricing)[0]
  const price = formatCost({
    amount: productData?.pricing?.[selectedCurrecy]?.value.toString(),
    currencyCode: selectedCurrecy
  })

  return (
    <div className="flex h-full flex-col justify-between pt-10">
      <div>
        <div className="flex flex-wrap">
          <h1 className="mr-auto text-3xl text-black">{productData.name}</h1>

          {/* TODO: Render tags like new eco-range etc if required here */}
        </div>

        {price && (
          <div
            className="mt-1 text-lg text-black"
            title={
              defaultCurrency &&
              `* Prices exclude taxes and shipping. Non-${defaultCurrency.toUpperCase()} prices are approximate based on current rates.`
            }
          >
            From {price} *
          </div>
        )}

        <div className="my-8">
          <h2 className="text-base text-black">{productData.strapline}</h2>
        </div>

        <div>
          <ul>
            {productData.features.map((feature) => (
              <li key={feature} className="mt-1 ml-2 list-inside list-disc">
                <Remark
                  rehypeReactOptions={{
                    components: {
                      p(props: JSX.IntrinsicAttributes) {
                        return <React.Fragment {...props} />
                      },
                      a(props: JSX.IntrinsicAttributes) {
                        return <a {...props} className="w-fit text-purple-500" target="_blank" rel="noreferrer" />
                      }
                    }
                  }}
                >
                  {feature}
                </Remark>
              </li>
            ))}
          </ul>
        </div>

        <div className="mt-10 mb-16">
          <Link className="btn btn-primary w-fit" to={ROUTE_PATHS.CREATE_ORDER}>
            Order now
          </Link>
        </div>
      </div>
      <div className="mb-10 flex max-w-full flex-wrap justify-between gap-6 overflow-x-auto border-y border-gray-200 p-6">
        <div className="flex items-center justify-center gap-3">
          <div className="flex flex-wrap items-center justify-center gap-2">
            {productData.manufacturing.regions.map((region) => (
              <img
                alt={region}
                key={region}
                className="h-10 w-10 rounded-full object-cover object-center"
                src={`/img/flags-100/${region}.png`}
                title={region}
              />
            ))}
          </div>
          {productData.manufacturing.regions.length}{' '}
          {productData.manufacturing.regions.length > 1 ? 'regions' : 'region'}
        </div>

        {productData.manufacturing.time && (
          <div className="flex items-center justify-center gap-3">
            <ClockIcon className="h-10 w-10 shrink-0 text-gray-600" />
            {productData.manufacturing.time}hr lead time
          </div>
        )}

        <div className="flex items-center justify-center gap-3">
          <GlobeIcon className="h-10 w-10 shrink-0 text-gray-600" />
          Ships to: {productData.manufacturing.shipsTo.join(', ')}
        </div>
      </div>
    </div>
  )
}
