import { useParams } from 'react-router'

import { CategoryCard, ProductCard } from '.'
import { CatalogueCategoryInterface, CatalogueProductInterface } from '../../../hooks'

export function CatalogueLoaded({
  productCatalogue
}: {
  productCatalogue: Record<string, CatalogueCategoryInterface | undefined>
}) {
  const { categorySlug, subCategorySlug, subSubCategorySlug } = useParams<{
    categorySlug?: string
    subCategorySlug?: string
    subSubCategorySlug?: string
  }>()

  const categoryData = categorySlug ? productCatalogue[categorySlug] : undefined
  const subCategoryData = categoryData && subCategorySlug ? categoryData.subCategories[subCategorySlug] : undefined
  const subSubCategoryData =
    subCategoryData && subSubCategorySlug ? subCategoryData.subCategories[subSubCategorySlug] : undefined

  const doesSubSubCategoryHaveProducts = subSubCategoryData && Object.keys(subSubCategoryData.products).length > 0
  if (doesSubSubCategoryHaveProducts) {
    return <ProductCards products={subSubCategoryData.products} />
  }

  const doesSubCategoryHaveProducts = subCategoryData && Object.keys(subCategoryData.products).length > 0
  if (doesSubCategoryHaveProducts) {
    return <ProductCards products={subCategoryData.products} />
  }

  const doesSubCategoryHaveSubSubCategories = subCategoryData && Object.keys(subCategoryData.subCategories).length > 0
  if (doesSubCategoryHaveSubSubCategories) {
    return <CategoryCards categories={subCategoryData.subCategories} />
  }

  const doesCategoryHaveProducts = categoryData && Object.keys(categoryData.products).length > 0
  if (doesCategoryHaveProducts) {
    return <ProductCards products={categoryData.products} />
  }

  const doesCategoryHaveSubCategories = categoryData && Object.keys(categoryData.subCategories).length > 0
  if (doesCategoryHaveSubCategories) {
    return <CategoryCards categories={categoryData.subCategories} />
  }

  return <CategoryCards categories={productCatalogue} />
}

function CategoryCards({ categories }: { categories: Record<string, CatalogueCategoryInterface | undefined> }) {
  return (
    <div className="grid max-w-full grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-8 overflow-x-auto pt-6">
      {Object.entries(categories).map(([categoryKey, categoryData]) => {
        return categoryData ? <CategoryCard key={categoryKey} categoryData={categoryData} /> : null
      })}
    </div>
  )
}

function ProductCards({ products }: { products: Record<string, CatalogueProductInterface | undefined> }) {
  return (
    <div className="grid max-w-full grid-cols-[repeat(auto-fill,minmax(270px,1fr))] gap-8 overflow-x-auto pt-6">
      {Object.entries(products).map(([productKey, productData]) => {
        return productData ? <ProductCard key={productKey} productData={productData} /> : null
      })}
    </div>
  )
}
