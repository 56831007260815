import React from 'react'
import { Remark } from 'react-remark'

import { FooterContainer } from '.'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function Packaging({ productData }: { productData: ProductCatalogueProductInterface }) {
  const formattedName = productData.pluralName[0].toUpperCase() + productData.pluralName.slice(1).toLowerCase()

  const hasNoPackaging = productData.packaging?.every((p) => p.slug === 'no-packaging')
  const hasMultipleTypes = productData.packaging?.length > 1

  const regionFormatter = new Intl.ListFormat('en', { style: 'long', type: 'disjunction' })

  return (
    <FooterContainer>
      {productData.global && (
        <div>
          <h3 className="mt-0">Fulfilment</h3>
          <p className="mt-4">{formattedName} are part of our global print on demand range.</p>
          <p className="mt-2">
            Order using a single SKU and we automatically fulfil your order from the print lab nearest to your customer.
          </p>
          <p className="mt-2">Global products are greener, faster and cheaper.</p>
        </div>
      )}

      {productData.packaging?.length > 0 && (
        <div>
          {!hasNoPackaging && (
            <div>
              <h3 className="mt-0">White-label packaging</h3>
              <p className="mt-4">
                All our shipments are white-label. There is no Prodigi branding on the outer packaging or on any
                included paperwork.
              </p>
            </div>
          )}

          {hasMultipleTypes && (
            <div className="mt-12">
              <h3 className="mt-0">Packaging types</h3>
              <p className="mt-4">
                {formattedName} are shipped in a range of packaging types depending on the size/variant or where the
                item is printed.
              </p>
            </div>
          )}

          {productData.packaging?.map((p) => (
            <div className="mt-12" key={p.slug}>
              <div className={p.images?.length > 0 ? 'xl:w-1/2' : ''}>
                <h4 className={hasMultipleTypes ? 'text-base' : ''}>{p.name}</h4>
                {(productData.packaging?.length === 1 || productData.manufacturing?.regions.length === 1) && (
                  <p className="mt-0 text-xs text-gray-600">When fulfilled from {regionFormatter.format(p.regions)}</p>
                )}
                <p className="mt-4 lg:pr-12">
                  <Remark
                    rehypeReactOptions={{
                      components: {
                        p(props: JSX.IntrinsicAttributes) {
                          return <React.Fragment {...props} />
                        },
                        a(props: JSX.IntrinsicAttributes) {
                          return <a {...props} className="w-fit text-purple-500" target="_blank" rel="noreferrer" />
                        }
                      }
                    }}
                  >
                    {p.description}
                  </Remark>
                </p>
              </div>
              {p.images?.length > 0 && (
                <ul className="mt-8 flex flex-wrap xl:w-1/4">
                  {p.images?.map((i) => (
                    <li className="w-1/2 p-1" key={i}>
                      <img className="h-[170px] w-[170px] object-contain object-center" src={i} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
    </FooterContainer>
  )
}
