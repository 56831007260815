import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CATALOGUE_PRODUCTS, QUERY_PARAMS, ROUTE_PATHS } from 'src/v2/constants'
import { RecommendedProductInterface } from '../interfaces'
import { clearProductSearch } from '../../../../actions/catalogue'
import { RECOMMENDED_PRODUCTS_CATALOGUE } from '../constants'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */
export function RecommendedProducts() {
  return (
    <div className="w-full pb-28">
      <div className="container mx-auto">
        <h2 className="mt-20 mb-5 text-black">Recommended products</h2>

        <p className="mb-16">Set your store up for success with our most-sold products.</p>

        <ul className="grid list-none grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
          {RECOMMENDED_PRODUCTS_CATALOGUE.map((details) => (
            <Product key={details.category + details.productType ?? ''} details={details} />
          ))}
        </ul>
      </div>
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */
function Product({ details }: { details: RecommendedProductInterface }) {
  const history = useHistory()
  const dispatch = useDispatch()

  const detailImage = details.image
  const categoryImage = CATALOGUE_PRODUCTS[details.category]?.image
  const productTypeImage = details.productType
    ? CATALOGUE_PRODUCTS[details.category]?.productTypes[details.productType]?.image
    : null

  function handleRecommendedProductClick() {
    window.analytics.track('Clicked Recommended Product', {
      productName: details.label
    })
    const categoryData = CATALOGUE_PRODUCTS[details.category]
    const isCategoryOnly = categoryData && Object.keys(categoryData.productTypes).length === 0

    const hasCategoryAndProductType =
      categoryData && details.productType && categoryData.productTypes[details.productType]

    if (hasCategoryAndProductType && details.productType) {
      dispatch(clearProductSearch())
      history.push(
        `${ROUTE_PATHS.MOF.SEARCH}?${QUERY_PARAMS.MOF.SEARCH.CATEGORY}=${encodeURIComponent(details.category)}&${
          QUERY_PARAMS.MOF.SEARCH.PRODUCT_TYPE
        }=${encodeURIComponent(details.productType)}`
      )
      return
    }

    if (isCategoryOnly) {
      dispatch(clearProductSearch())
      history.push(
        `${ROUTE_PATHS.MOF.SEARCH}?${QUERY_PARAMS.MOF.SEARCH.CATEGORY}=${encodeURIComponent(details.category)}`
      )
      return
    }

    history.push(`${ROUTE_PATHS.PRODUCTS}/${encodeURIComponent(details.category)}`)
  }

  const noImageFound = !detailImage && !productTypeImage && !categoryImage
  if (noImageFound) {
    return null
  }

  return (
    <motion.li whileHover="hover" className="w-full" key={details.category}>
      <button className="flex w-full cursor-pointer flex-col gap-4" onClick={handleRecommendedProductClick}>
        <div className="aspect-square w-full overflow-hidden rounded border border-gray-200">
          <motion.img
            variants={{
              hover: {
                scale: 1.05
              }
            }}
            alt={details.label}
            src={detailImage || productTypeImage || categoryImage}
            className="w-full object-contain object-center"
          />
        </div>

        <span className="text-left text-black">{details.label}</span>
      </button>
    </motion.li>
  )
}
