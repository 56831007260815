import { ImageLibraryImageType } from '../../../hooks'

export function formatFileTypeFilter(typeValues: string) {
  return (typeValues.split(',') as ImageLibraryImageType[]).map(formatImageLibraryTypeName).join(', ')
}

function formatImageLibraryTypeName(type: ImageLibraryImageType) {
  switch (type) {
    case 'artwork':
      return 'Artwork'
    case 'packing':
      return 'Packing slip'
    case 'book':
      return 'Book'
    default:
      return type
  }
}
