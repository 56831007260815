function CareInstructionsBubbles({ className = '' }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 52 54"
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
    >
      <title>Bubble icon</title>
      <g transform="translate(-834.000000, -4882.000000)">
        <g transform="translate(135.000000, 4728.000000)">
          <g transform="translate(700.000000, 154.000000)">
            <path d="M27,20.5 C27,27.95575 20.95575,34 13.5,34 C6.04425,34 0,27.95575 0,20.5 C0,13.04425 6.04425,7 13.5,7 C20.95575,7 27,13.04425 27,20.5"></path>
            <path
              d="M27,20.5 C27,27.95575 20.95575,34 13.5,34 C6.04425,34 0,27.95575 0,20.5 C0,13.04425 6.04425,7 13.5,7 C20.95575,7 27,13.04425 27,20.5 Z"
              strokeWidth="2"
            ></path>
            <path d="M50,31 C50,40.3892889 42.3892889,48 33,48 C23.6107111,48 16,40.3892889 16,31 C16,21.6107111 23.6107111,14 33,14 C42.3892889,14 50,21.6107111 50,31"></path>
            <path
              d="M50,31 C50,40.3892889 42.3892889,48 33,48 C23.6107111,48 16,40.3892889 16,31 C16,21.6107111 23.6107111,14 33,14 C42.3892889,14 50,21.6107111 50,31 Z"
              strokeWidth="2"
            ></path>
            <path d="M38,19 C40.638448,20.1626654 42.7795414,22.3101421 44,25" strokeWidth="2"></path>
            <path d="M29,44.5 C29,49.1943182 25.1943182,53 20.5,53 C15.8056818,53 12,49.1943182 12,44.5 C12,39.8056818 15.8056818,36 20.5,36 C25.1943182,36 29,39.8056818 29,44.5"></path>
            <path
              d="M29,44.5 C29,49.1943182 25.1943182,53 20.5,53 C15.8056818,53 12,49.1943182 12,44.5 C12,39.8056818 15.8056818,36 20.5,36 C25.1943182,36 29,39.8056818 29,44.5 Z"
              strokeWidth="2"
            ></path>
            <path
              d="M42,3.5 C42,4.88071429 40.8807143,6 39.5,6 C38.1192857,6 37,4.88071429 37,3.5 C37,2.11928571 38.1192857,1 39.5,1 C40.8807143,1 42,2.11928571 42,3.5 Z"
              strokeWidth="2"
            ></path>
            <g transform="translate(21.000000, 40.000000)">
              <path d="M0.25704,0.109125 C1.84024,0.442875 3.12744,1.509375 3.69144,2.908875"></path>
              <path d="M0.25704,0.109125 C1.84024,0.442875 3.12744,1.509375 3.69144,2.908875" strokeWidth="2"></path>
            </g>
            <line x1="5.5" y1="41" x2="5.5" y2="44" strokeWidth="2"></line>
            <line x1="5.5" y1="45" x2="5.5" y2="48" strokeWidth="2"></line>
            <line x1="2" y1="44.5" x2="5" y2="44.5" strokeWidth="2"></line>
            <line x1="6" y1="44.5" x2="9" y2="44.5" strokeWidth="2"></line>
            <line x1="26.5" y1="0" x2="26.5" y2="3" strokeWidth="2"></line>
            <line x1="26.5" y1="4" x2="26.5" y2="7" strokeWidth="2"></line>
            <line x1="23" y1="3.5" x2="26" y2="3.5" strokeWidth="2"></line>
            <line x1="27" y1="3.5" x2="30" y2="3.5" strokeWidth="2"></line>
            <line x1="31.5" y1="34" x2="31.5" y2="36" strokeWidth="2"></line>
            <line x1="9.5" y1="38" x2="9.5" y2="40" strokeWidth="2"></line>
            <line x1="12.5" y1="15" x2="12.5" y2="17" strokeWidth="2"></line>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { CareInstructionsBubbles }
