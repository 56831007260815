import { DownloadIcon } from '@heroicons/react/outline'

import { FooterContainer } from '.'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function Assets({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <FooterContainer>
      {productData.photographyAssets.assets.length > 0 && (
        <div>
          <div className="flex flex-wrap items-center justify-between gap-3">
            <h3 className="mt-0">Product photography</h3>
            <a
              className="btn btn-secondary mt-4 w-fit md:mt-0"
              href={productData.photographyAssets.downloadAll}
              rel="noreferrer noopener"
              target="_blank"
            >
              Download all
            </a>
          </div>
          <ul className="mt-4 grid max-w-full grid-cols-[repeat(auto-fill,minmax(180px,1fr))] gap-6 md:gap-10">
            {productData.photographyAssets.assets.map((asset) => (
              <li key={asset.name}>
                <a className="w-fit cursor-pointer" href={asset.download} rel="noreferrer noopener" target="_blank">
                  <img className="aspect-square h-auto w-full object-cover object-center" src={asset.thumbnail} />
                  <span className="mt-2 flex items-start justify-between">
                    {asset.name ? asset.name[0].toUpperCase() + asset.name.slice(1).toLowerCase() + ' ' : ''}
                    <DownloadIcon className="ml-4 hidden h-10 w-8 shrink-0 text-purple-500 md:block" />
                  </span>{' '}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {productData.blankPhotographyAssets.length > 0 && (
        <div>
          <h3 className="mt-0">Blank product photography</h3>
          <p className="mt-4">
            Blank {productData.singularName.toLowerCase()} assets for easy compositing with your own imagery.
          </p>
          <ul className="mt-4 list-outside list-disc">
            {productData.blankPhotographyAssets.map((asset) => (
              <li className="mt-2 ml-8" key={asset.name}>
                <a
                  className="flex w-fit cursor-pointer text-purple-500 hover:underline"
                  href={asset.urlSlug}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {asset.name}
                  <DownloadIcon className="ml-4 h-8 w-8" />
                </a>
              </li>
            ))}
          </ul>
          <p className="mt-4 text-gray-600">All blank assets are zipped pngs.</p>
        </div>
      )}

      {productData.mockups && (
        <div>
          <h3 className="mt-0">Personalised mockups</h3>
          <ul className="mt-4 list-outside list-disc">
            <li className="mt-2 ml-8">
              <a
                className="flex w-fit cursor-pointer text-purple-500 hover:underline"
                href={`https://mockups.prodigi.com/mockups/${productData.urlSlug}`}
                rel="noreferrer noopener"
                target="_blank"
              >
                Mockup generator
              </a>
              <span className="text-gray-600">
                Create custom {productData.singularName} imagery using your own artworks and designs. Completely free
                and no usage limits.
              </span>
            </li>
          </ul>
        </div>
      )}

      {productData.productSheet !== '' && (
        <div>
          <h3 className="mt-0">Product sheet</h3>
          <ul className="mt-4 list-outside list-disc">
            <li className="mt-2 ml-8">
              <a
                className="flex w-fit cursor-pointer text-purple-500 hover:underline"
                href={productData.productSheet}
                rel="noreferrer noopener"
                target="_blank"
              >
                {productData.name} product sheet
                <DownloadIcon className="ml-4 h-8 w-8" />
              </a>
              <span className="text-gray-600">pdf</span>
            </li>
          </ul>
        </div>
      )}

      {productData.externalLinks?.length > 0 && (
        <div>
          <h3 className="mt-0">External links</h3>
          <ul className="mt-4 list-outside list-disc">
            {productData.externalLinks.map((link) => (
              <li className="mt-2 ml-8" key={link.name}>
                <a
                  className="flex w-fit cursor-pointer text-purple-500 hover:underline"
                  href={link.url}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {link.name}
                </a>
                <span className="text-gray-600">{link.description}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </FooterContainer>
  )
}
