import FormItem from '../../FormItem'
import SelectField from '../../SelectField'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { ImageLibraryImageType, useSplitToggle } from '../../../hooks'

export function ImageType({
  isLoading,
  value,
  onUpdate
}: {
  isLoading: boolean
  value?: string
  onUpdate: (value: ImageLibraryImageType) => void
}) {
  const { splitIsOn: isPDFSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.IMAGE_LIBRARY_PDF })

  if (!isPDFSplitOn) {
    return (
      <FormItem
        key="image-details-type"
        inputField={
          <SelectField
            className="w-full"
            disabled={isLoading}
            value={value}
            onChange={(event) => onUpdate(event.target.value as ImageLibraryImageType)}
          >
            <option key="artwork" value="artwork">
              Artwork
            </option>
            <option key="packing" value="packing">
              Packing slip
            </option>
          </SelectField>
        }
        labelText="Type"
      />
    )
  }

  return (
    <FormItem
      key="image-details-type"
      inputField={
        <SelectField
          className="w-full"
          disabled={isLoading}
          value={value}
          onChange={(event) => onUpdate(event.target.value as ImageLibraryImageType)}
        >
          <option key="artwork" value="artwork">
            Artwork
          </option>
          <option key="packing" value="packing">
            Packing slip
          </option>
          <option key="book" value="book">
            Book
          </option>
        </SelectField>
      }
      labelText="Type"
    />
  )
}
