import { ImageLibraryFileType } from '../hooks'

export const PRODUCT_FILES_COMPATIBILITY_DATA: Array<{
  productType: string
  fileTypesAllowed: ImageLibraryFileType[]
}> = [
  { productType: 'Hardcover Books', fileTypesAllowed: ['pdf (multi-page)'] },
  { productType: 'Softcover Books', fileTypesAllowed: ['pdf (multi-page)'] }
]

export function getProductFileCompatibilityData({ productType }: { productType: string }) {
  const productFileCompatibilityData = PRODUCT_FILES_COMPATIBILITY_DATA.find(
    (productFileData) => productFileData.productType.toUpperCase() === productType?.toUpperCase()
  )
  return productFileCompatibilityData
}
