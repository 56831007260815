export const QUERY_PARAMS = {
  DOWNLOAD: {
    FILE_ID: 'fileId'
  },
  IMAGE_LIBRARY: {
    ORDER_BY: 'imageLibraryOrderBy',
    SEARCH: 'imageLibrarySearch',
    TYPE: 'imageLibraryType',
    // TODO: Can remove when removing Split IMAGE_LIBRARY_PDF: 'AllowPDFUploadsToImageLibrary'
    FILE_FORMAT: 'imageLibraryFileFormat',
    FILE_TYPE: 'imageLibraryFileType',
    ORIENTATION: 'imageLibraryOrientation',
    TAG: 'imageLibraryTag',
    TAG_SEARCH: 'imageLibraryTagSearch'
  },
  IMAGE_DETAIL: {
    PREV: 'prev'
  },
  LOGIN: {
    REDIRECT: 'redirect'
  },
  ORDERS: {
    STATUS: 'OrderStatuses',
    SORT: 'sortOrder',
    SEARCH: 'searchTerm',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    SOURCE: 'channelIds'
  },
  ORDER_DETAIL: {
    PREV: 'prev',
    SUCCESS: 'success'
  },
  PASSWORD_CHANGE: {
    EMAIL: 'email',
    TOKEN: 'token'
  },
  PAYMENT: {
    ORDER_ID: 'orderId',
    FULFILMENT_ID: 'fulfilmentId'
  },
  SALES_CHANNEL: {
    CONFIGURE: {
      SEARCH: 'searchTerm',
      STATUS: 'fulfillmentStatuses'
    },
    CONNECT_REDIRECT: {
      TOKEN: 'public_token',
      CODE: 'code'
    }
  },
  MOF: {
    SEARCH: {
      CATEGORY: 'category',
      PRODUCT_TYPE: 'productType'
    }
  }
}
