import { twMerge } from 'tailwind-merge'

export function PromoBlocks() {
  return (
    <div className="mt-20 grid grid-cols-1 gap-6 overflow-x-auto lg:grid-cols-2">
      <PromoBlock
        backgroundImage="/img/v2/browse-products/portfolio.png"
        buttonLink="https://www.prodigi.com/download/product-range/prodigi-portfolio.pdf"
        buttonText="Download portfolio"
        className="bg-white"
        content="Our entire product catalogue in a single pdf."
        heading="Product Portfolio"
      />

      <PromoBlock
        backgroundImage="/img/v2/browse-products/new-this-month.png"
        buttonLink="https://www.prodigi.com/products/glow-in-the-dark-prints"
        buttonText="Glow in the dark prints"
        className="bg-cyan-100"
        content="Glow in the dark prints now shipped from the US."
        heading="New in this month"
      />
    </div>
  )
}

interface PromoBlockInterface {
  backgroundImage: string
  buttonLink: string
  buttonText: string
  className: string
  content: string
  heading: string
}

function PromoBlock({ backgroundImage, buttonLink, buttonText, className, content, heading }: PromoBlockInterface) {
  return (
    <div className={twMerge('flex min-h-[250px] border', className)}>
      <div className="flex flex-1 flex-col p-8">
        <h2 className="mb-4">{heading}</h2>
        <p>{content}</p>

        <div className="mt-auto">
          <a
            className="btn btn-primary w-fit whitespace-nowrap"
            href={buttonLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {buttonText}
          </a>
        </div>
      </div>

      <div className="flex-1 bg-cover bg-no-repeat" style={{ backgroundImage: `url(${backgroundImage})` }} />
    </div>
  )
}
