import { useState } from 'react'
import { Link } from 'react-router-dom'

import Modal from '../../Modal'
import { useSplitToggle, useUser } from '../../../hooks'
import { ROUTE_PATHS } from '../../../constants'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { ArchiveIcon, ClipboardCheckIcon, PlayIcon, SparklesIcon, TagIcon } from '@heroicons/react/outline'
import { FEATURE_NAMES } from 'src/split-io/feature-names'

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

export function DashboardPromoFields({
  hasPaymentCardSetup,
  hasPlacedOrder,
  hasPlacedSamplePackOrder
}: {
  hasPaymentCardSetup: boolean
  hasPlacedOrder: boolean
  hasPlacedSamplePackOrder: boolean
}) {
  const { splitIsOn: isShopifySplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_SHOPIFY_REVIEW })

  return (
    <div className="lg:grid-rows grid gap-8 sm:grid-cols-2 sm:grid-rows-[minmax(250px,_1fr),_minmax(0px,_1fr)] lg:grid-cols-3 lg:grid-rows-[repeat(auto-fit,minmax(250px,1fr))]">
      {!isShopifySplitOn && <ProductsPanel />}

      {!isShopifySplitOn && !hasPlacedSamplePackOrder && <SamplePackPanel />}

      <USPricingPanel />

      {!isShopifySplitOn && hasPlacedSamplePackOrder && <PricingPanel />}

      {!hasPlacedOrder && <HowToPlaceOrderPanel />}

      {hasPlacedOrder && <OrdersPanel />}

      {!hasPaymentCardSetup && <BillingPanel />}
    </div>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */

function ProductsPanel() {
  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  return (
    <Panel
      icon={<ArchiveIcon className="max-w-full rounded-full bg-purple-200 stroke-[1.5] p-6 text-purple-600" />}
      isExternal={false}
      linkAddress={isAPCSplitOn ? ROUTE_PATHS.PRODUCT_CATALOGUE : ROUTE_PATHS.PRODUCTS}
      linkText="View products"
      headingText="Products"
      bodyText="Wall art, t-shirts, phone cases and more."
      eventText="Clicked - View products"
    />
  )
}

function SamplePackPanel() {
  const { user } = useUser()

  const isUserCountryUS = user?.company.countryCode === 'US'
  if (isUserCountryUS) {
    return null
  }

  return (
    <Panel
      eventText="Clicked - Order sample pack"
      icon={<SparklesIcon className="max-w-full rounded-full bg-magenta-100 stroke-[1.5] p-6 text-magenta-500" />}
      isExternal={false}
      linkAddress="/products/PRODIGI-SAMPLE-PACK"
      linkText="Order sample pack"
      headingText="Sample pack"
      bodyText="See and feel the Prodigi quality for yourself."
    />
  )
}

function PricingPanel() {
  const { user } = useUser()
  const { splitIsOn: isAPCSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_APC })

  const isUserCountryUS = user?.company.countryCode === 'US'
  if (isUserCountryUS) {
    return null
  }

  return (
    <Panel
      eventText="Clicked - View pricing"
      icon={<TagIcon className="max-w-full rounded-full bg-cyan-100 stroke-[1.5] p-6 text-cyan-800" />}
      isExternal={false}
      linkAddress={isAPCSplitOn ? ROUTE_PATHS.PRODUCT_CATALOGUE : ROUTE_PATHS.PRODUCT_INFO}
      linkText="Get pricing"
      headingText="Pricing"
      bodyText="Check product pricing and shipping rates."
    />
  )
}

function USPricingPanel() {
  const { user } = useUser()

  const isUserCountryUS = user?.company.countryCode === 'US'
  if (!isUserCountryUS) {
    return null
  }

  return (
    <div className="mt-16 flex flex-col justify-between rounded-lg bg-white p-12 shadow-md lg:mt-32">
      <div className="-mt-24 h-24 w-24">
        {<TagIcon className="max-w-full rounded-full bg-cyan-100 stroke-[1.5] p-6 text-cyan-800" />}
      </div>
      <div className="flex gap-2">
        <div>
          <h2 className="mt-12 text-lg font-medium">Printed in the USA</h2>
          <p className="mt-2 text-gray-600">Download a CSV of our products made and shipped from the USA.</p>
          <PanelButton
            className="mt-6"
            isExternal={true}
            isLink={true}
            linkAddress="https://prodigipricingpublic.blob.core.windows.net/public/ustest/prodigi-madeinusa.csv"
            linkText="Download CSV"
            eventText="Clicked - Download US pricing CSV"
          />
        </div>
        <div className="hidden xl:block">
          <img alt="Made in USA" className="h-[150px] w-[250px] object-contain" src="/img/made-in-usa.jpg" />
        </div>
      </div>
    </div>
  )
}

function HowToPlaceOrderPanel() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Panel
        eventText="Clicked - Watch our video"
        icon={<PlayIcon className="max-w-full rounded-full bg-green-100 stroke-[1.5] p-6 text-green-700" />}
        isExternal={false}
        isLink={false}
        onClick={() => setOpen(true)}
        linkAddress=""
        linkText="Watch tutorial"
        headingText="How to place an order"
        bodyText="Learn more about placing an order with Prodigi."
      />

      <Modal
        className="rounded-none"
        showBorder={false}
        showHeader={false}
        open={open}
        setOpen={setOpen}
        closeOnInteractionOutside={true}
      >
        <div className="h-50vh w-80vw max-w-[1200px] overflow-auto bg-white">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/dSCCwvXJic0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </>
  )
}

function OrdersPanel() {
  return (
    <Panel
      eventText="Clicked - Review your orders"
      icon={<ClipboardCheckIcon className="max-w-full rounded-full bg-teal-100 stroke-[1.5] p-6 text-teal-800" />}
      isExternal={false}
      linkAddress="/orders"
      linkText="View order history"
      headingText="Orders"
      bodyText="View the status and details of your orders."
    />
  )
}

function BillingPanel() {
  return (
    <div className="relative col-span-full mt-16 flex flex-col justify-between rounded-lg bg-white p-8 shadow-md md:flex-row lg:mt-0">
      <div className="flex flex-col items-start sm:flex-row sm:items-center">
        <InformationCircleIcon className="mr-4 mb-4 h-10 w-10 text-purple-400 sm:mb-0" />
        <p>We need a registered card on your account to take orders.</p>
      </div>
      <PanelButton
        className="mt-8 md:mt-0"
        eventText="Clicked - Add card details"
        isExternal={false}
        isLink={false}
        linkAddress="/settings/billing"
        linkText="Add card details"
      />
    </div>
  )
}

function Panel({
  eventText,
  icon,
  isExternal,
  isLink = true,
  linkText,
  linkAddress,
  headingText,
  bodyText,
  onClick
}: {
  eventText: string
  icon: React.ReactNode
  isExternal: boolean
  isLink?: boolean
  linkText: string
  linkAddress: string
  headingText: string
  bodyText: string
  onClick?: () => void
}) {
  return (
    <div className="mt-16 flex flex-col justify-between rounded-lg bg-white p-12 shadow-md lg:mt-32">
      {icon && <div className="-mt-24 h-24 w-24">{icon}</div>}
      <h2 className="mt-10 text-lg font-medium">{headingText}</h2>
      <p className="mt-2 text-gray-600">{bodyText}</p>
      <PanelButton
        className="mt-6"
        isExternal={isExternal}
        isLink={isLink}
        linkAddress={linkAddress}
        linkText={linkText}
        eventText={eventText}
        onClick={onClick}
      />
    </div>
  )
}

function PanelButton({
  className,
  eventText,
  isExternal,
  isLink,
  linkAddress,
  linkText,
  onClick
}: {
  className?: string
  eventText: string
  isExternal: boolean
  isLink: boolean
  linkAddress: string
  linkText: string
  onClick?: () => void
}) {
  function sendEvent() {
    window.analytics.track(eventText)
  }

  if (isExternal) {
    return (
      <a
        className={`btn btn-secondary w-fit ${className}`}
        href={linkAddress}
        rel="noreferrer"
        target="_blank"
        onClick={sendEvent}
      >
        {linkText}
      </a>
    )
  }

  if (!isLink && onClick) {
    return (
      <button
        className={`btn btn-secondary w-fit ${className}`}
        onClick={() => {
          onClick()
          sendEvent()
        }}
      >
        {linkText}
      </button>
    )
  }

  return (
    <Link className={`btn btn-secondary w-fit ${className}`} to={linkAddress} onClick={sendEvent}>
      {linkText}
    </Link>
  )
}
