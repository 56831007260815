import { ReactNode } from 'react'
import { TrashIcon } from '@heroicons/react/outline'

import Button from '../../Button'
import { StatusType } from '../../../interfaces'
import { ToggleGroup } from './ToggleGroup.component'
import MockupsView from '../../ImageEditorMockupView'
import { PrintAreasPanel } from './PrintAreasPanel.component'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { MockupPollCompleteInterface } from './MockupsPolling.component'
import { ImageMockupInterface, useMockups, useMockups3D, useSplitToggle } from '../../../hooks'
import { Divider, EditorControlPanel, EditorControlPanelPropsInterface } from './EditorControlPanel.component'

export type ImageEditorViewsType = 'design' | 'mockup'

export type ViewTabs = { id: ImageEditorViewsType; label: string }[]

const viewTabs: ViewTabs = [
  { id: 'design', label: 'Design view' },
  { id: 'mockup', label: 'Mockup view' }
]

interface MultiAssetImageEditorControlPanelPropsInterface extends EditorControlPanelPropsInterface {
  attributes: Record<string, string>
  imageLibraryId?: string | null
  mockupData: ImageMockupInterface | null
  mockupGenerationStatus: StatusType
  productSku: string
  selectedMockupView: string | null
  selectedView: ImageEditorViewsType
  transformationId: string | null
  onMockupsPollingComplete: (details: MockupPollCompleteInterface) => void
  onViewSelected: (view: string) => Promise<void>
  onSelectMockupView: (view: string) => void
}

export default function MultiAssetImageEditorControlPanel(props: MultiAssetImageEditorControlPanelPropsInterface) {
  const {
    isLoading,
    error: hasError,
    mockups,
    mockup
  } = useMockups({
    attributes: props.attributes,
    productSku: props.productSku,
    orientation: props.templateOrientation,
    orientationSelectionAvailable: props.showTemplateOrientationToggle
  })
  const {
    isLoadingMockups,
    mockups: mockups3d,
    mockupsFetchError
  } = useMockups3D({
    attributes: props.attributes,
    orientation: props.templateOrientation,
    productSku: props.productSku
  })

  const { splitIsOn: is3DMockupsOn } = useSplitToggle({ toggle: FEATURE_NAMES.DASHBOARD_3D_MOCKUPS })

  let hideMockupsToggle = true
  if (is3DMockupsOn) {
    hideMockupsToggle = Boolean(
      isLoadingMockups ||
        mockupsFetchError ||
        !mockups3d ||
        Object.keys(mockups3d?.views).length === 0 ||
        !props.imageLibraryId
    )
  } else {
    hideMockupsToggle = Boolean(isLoading || hasError || !mockups || !mockup || !props.imageLibraryId)
  }

  if (props.artwork?.mimeType === 'application/pdf') {
    return (
      <ControlPanelContainer productName={props.productName}>
        {props.printAreaArtworkEntries.length > 1 && (
          <>
            <Divider />
            <p className="text-xs font-medium uppercase text-white">SIDE</p>
            <PrintAreasPanel
              imageLibraryConfig={props.imageLibraryConfig}
              itemCategory={props.itemCategory}
              productType={props.productType}
              printAreaArtworkEntries={props.printAreaArtworkEntries}
              selectedPrintArea={props.selectedPrintArea}
              templates={props.templates}
              onChangePrintArea={props.onChangePrintArea}
              onRemoveArtwork={props.onRemoveArtwork}
              onSelectImageLibraryImage={props.onSelectImageLibraryImage}
              onTogglePreviewMode={props.onTogglePreviewMode}
            />
          </>
        )}
        <Divider />
        <div className="text-lg text-white">Viewing pdf file</div>
        <div className="text-gray-300">This product requires a print-ready pdf file, which are view-only.</div>
        <div className="mt-8">
          <Button
            variant="none"
            size="sm"
            className="btn border-gray-700 text-gray-300 hover:border-gray-300"
            startIcon={<TrashIcon className="h-6 w-6" />}
            onClick={() => {
              props.onRemoveArtwork(props.selectedPrintArea)
            }}
          >
            Remove file
          </Button>
        </div>
      </ControlPanelContainer>
    )
  }

  return (
    <ControlPanelContainer productName={props.productName}>
      {hideMockupsToggle && <Divider />}

      {!hideMockupsToggle && (
        <ToggleGroup
          className="mb-16 max-w-[320px]"
          layoutId="mockup-toggle"
          value={props.selectedView}
          viewTabs={viewTabs}
          onChange={props.onViewSelected}
        />
      )}

      {props.selectedView === 'design' && <EditorControlPanel {...props} />}

      {props.selectedView === 'mockup' && (
        <MockupsView
          attributes={props.attributes}
          mockupGenerationStatus={props.mockupGenerationStatus}
          mockupData={props.mockupData}
          orientation={props.templateOrientation}
          orientationSelectionAvailable={props.showTemplateOrientationToggle}
          productSku={props.productSku}
          selectedMockupView={props.selectedMockupView}
          transformationId={props.transformationId}
          onMockupsPollingComplete={props.onMockupsPollingComplete}
          onSelectMockupView={props.onSelectMockupView}
        />
      )}
    </ControlPanelContainer>
  )
}

function ControlPanelContainer({ productName, children }: { productName: string; children: ReactNode }) {
  return (
    <div className="bg-gray-900 px-8 py-12 md:min-w-[390px] md:max-w-[390px] md:py-16 md:px-14">
      <div className="mb-16" data-test="image-editor-control-panel-product-name">
        <h1 className="m-0 text-xl font-medium text-white md:max-w-lg">{productName}</h1>
      </div>

      {children}
    </div>
  )
}
