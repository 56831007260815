import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/solid'
import { ReactNode } from 'react'
import { Table } from '@tanstack/react-table'

import Button from '../../Button'
import SelectField from '../../SelectField'
import { VariantRowType } from '../../../hooks'
import { VARIANTS_TABLE_MIN_PAGE_SIZE } from './Variants.component'

export function VariantsPagination({ table }: { table: Table<VariantRowType> }) {
  return (
    <div className="flex flex-wrap-reverse items-center gap-4">
      <div className="mr-auto">
        <SelectField
          className="mt-0 w-fit"
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[VARIANTS_TABLE_MIN_PAGE_SIZE, 50, 75, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </SelectField>
      </div>

      <div className="flex gap-4">
        <div className="flex items-center gap-x-4">
          <PaginationButton disabled={!table.getCanPreviousPage()} onClick={() => table.firstPage()}>
            <ChevronDoubleLeftIcon className="h-7 w-7" />
          </PaginationButton>

          <PaginationButton disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()}>
            <ChevronLeftIcon className="h-7 w-7" />
          </PaginationButton>
        </div>

        <div className="flex items-center gap-2">
          <span className="whitespace-nowrap">Page</span>
          <SelectField
            className="mt-0 w-fit"
            value={table.getState().pagination.pageIndex + 1}
            onChange={(event) => {
              const page = event.target.value ? Number(event.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
          >
            {Array.from({ length: table.getPageCount() }, (_, i) => i).map((pageNumber) => (
              <option key={pageNumber} value={pageNumber + 1}>
                {pageNumber + 1}
              </option>
            ))}
          </SelectField>{' '}
          <span className="whitespace-nowrap">of {table.getPageCount().toLocaleString()}</span>
        </div>

        <div className="flex items-center">
          <PaginationButton disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>
            <ChevronRightIcon className="h-7 w-7" />
          </PaginationButton>

          <PaginationButton disabled={!table.getCanNextPage()} onClick={() => table.lastPage()}>
            <ChevronDoubleRightIcon className="h-7 w-7" />
          </PaginationButton>
        </div>
      </div>
    </div>
  )
}

function PaginationButton({
  children,
  disabled,
  onClick
}: {
  children: ReactNode
  disabled: boolean
  onClick: () => void
}) {
  return (
    <Button
      variant="none"
      className="h-full px-3 text-purple-500 hover:text-purple-600 disabled:pointer-events-auto disabled:cursor-not-allowed disabled:opacity-75"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}
