// @flow
export const FEATURE_NAMES = {
  SALES_CHANNEL_SHOPIFY: 'sales_channel_shopify',
  TRANSORM_URLS: 'dashboard_transform_urls',
  DASHBOARD_SHOPIFY_REVIEW: 'dashboard_shopify_review',
  SALES_CHANNELS_DELETE_ORDERS: 'sales_channels_delete_orders',
  REQUIRES_ATTENTION_EMAILS: 'dashboard_requires_attention_emails',
  IMAGE_LIBRARY_PDF: 'AllowPDFUploadsToImageLibrary',
  DASHBOARD_APC: 'dashboard_apc',
  COSTED_ATTRIBUTES: 'dashboard_costed_attributes',
  SALES_CHANNEL_VOTE_FOR_PLATFORM: 'sales_channel_vote_for_platform',
  DASHBOARD_3D_MOCKUPS: 'dashboard_3d_mockups'
}
