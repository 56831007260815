export const HOTKEYS = {
  IMAGE_LIBRARY: {
    SEARCH: {
      TRIGGER: 'alt + k',
      DISPLAY: {
        APPLE: '⌥ K',
        DEFAULT: 'Alt K'
      }
    },
    TYPE: {
      TRIGGER: 'alt + t',
      DISPLAY: {
        APPLE: '⌥ T',
        DEFAULT: 'Alt T'
      }
    },
    FILE_FORMAT: {
      TRIGGER: 'alt + f',
      DISPLAY: {
        APPLE: '⌥ F',
        DEFAULT: 'Alt F'
      }
    },
    ORIENTATION: {
      TRIGGER: 'alt + o',
      DISPLAY: {
        APPLE: '⌥ O',
        DEFAULT: 'Alt O'
      }
    },
    TAG: {
      TRIGGER: 'alt + a',
      DISPLAY: {
        APPLE: '⌥ A',
        DEFAULT: 'Alt A'
      }
    },
    TAG_SEARCH: {
      TRIGGER: 'alt + r',
      DISPLAY: {
        APPLE: '⌥ R',
        DEFAULT: 'Alt R'
      }
    }
  },
  ORDERS: {
    STATUS: {
      TRIGGER: 'alt + s',
      DISPLAY: {
        APPLE: '⌥ S',
        DEFAULT: 'Alt S'
      }
    },
    DATE: {
      TRIGGER: 'alt + d',
      DISPLAY: {
        APPLE: '⌥ D',
        DEFAULT: 'Alt D'
      }
    },
    SOURCE: {
      TRIGGER: 'alt + c',
      DISPLAY: {
        APPLE: '⌥ C',
        DEFAULT: 'Alt C'
      }
    },
    SEARCH: {
      TRIGGER: 'alt + k',
      DISPLAY: {
        APPLE: '⌥ K',
        DEFAULT: 'Alt K'
      }
    }
  },
  COUNTRY_PICKER: {
    SEARCH: {
      TRIGGER: 'alt + k',
      DISPLAY: {
        APPLE: '⌥ K',
        DEFAULT: 'Alt K'
      }
    }
  },
  SALES_CHANNEL: {
    CONFIGURE: {
      SEARCH: {
        TRIGGER: 'alt + k',
        DISPLAY: {
          APPLE: '⌥ K',
          DEFAULT: 'Alt K'
        }
      }
    }
  }
}
