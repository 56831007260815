import useSWR from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface } from '../interfaces'

export interface ImageSpecDimensionsInterface {
  variants: string[]
  printAreas: { area: string; dimensions: string[] }[]
}

interface ImageSpecInterface {
  dpi: string
  fileTypes: string[]
  notes: string | null
  dimensions: ImageSpecDimensionsInterface[]
}

interface ProductMaterialsInterface {
  items: string[]
  notes: string | null
}

interface ProductManufacturingInterface {
  regions: string[]
  time: string | null
  shipsTo: string[]
}

interface ProductSizesInterface {
  description: string | null
  notes: string | null
  data: Record<string, SizeDetailsInterface[]>
}

interface SizeDetailsInterface {
  label: string
  value: string
  valueInMM: string
  sizeUnit: string
}

export interface ProductFrameVariantInterface {
  name: string
  chevron: string
  swatch?: string
  description?: string
  tone?: string
  decor?: string
}

interface ProductFramesInterface {
  name: string
  schematic?: string
  dimensions: { label: string; valueInMM: number }[]
  variants: ProductFrameVariantInterface[]
}

interface ProductColourInterface {
  name: string
  hex: string
}

interface ProductPackaging {
  slug: string
  name: string
  description: string
  images: string[]
  regions: string[]
}

interface ProductEcoCredentialsTypes {
  title: string
  abbreviation: 'ei' | 'lf' | 'ni' | 'or' | 'pf' | 'rc' | 'rp' | 'ry' | 'ss' | 'vi' | 'vg' | 'wi'
  shortDescription: string
  longDescription: string
}

interface ProductEcoCredentials {
  types: ProductEcoCredentialsTypes[]
  notes: string | null
}

type AttributeList = {
  value: string[]
}

export type VariantRowType = {
  description: string
  sku: string
  price: string
  frameColour?: {
    value: Array<{ name: string; url: string }>
  }
  colours?: {
    value: Array<{ name: string; hex: string }>
  }
  mountColour?: {
    value: Array<{ name: string; hex: string }>
  }
  glaze?: AttributeList
  style?: AttributeList
  paperType?: AttributeList
  frame?: AttributeList
  gender?: AttributeList
  mount?: AttributeList
  finish?: AttributeList
  edge?: AttributeList
  substrateWeight?: AttributeList
  size?: AttributeList
  brand?: AttributeList
  wrap?: AttributeList
  sla?: AttributeList
  orientation?: AttributeList
  fulfilledFrom?: AttributeList
}

// export type ColumnFilterType = 'text' | 'select' | 'none' | 'multi'
export type ColumnFilterType = 'none' | 'multi'

type BaseVariantColumnType = {
  enableSorting: boolean
  name: string
  filterType: ColumnFilterType
  options: string[]
}

export type VariantColumnType = {
  description?: BaseVariantColumnType
  price?: BaseVariantColumnType
  frameColour?: BaseVariantColumnType
  frame?: BaseVariantColumnType
  colours?: BaseVariantColumnType
  mountColour?: BaseVariantColumnType
  glaze?: BaseVariantColumnType
  style?: BaseVariantColumnType
  paperType?: BaseVariantColumnType
  gender?: BaseVariantColumnType
  mount?: BaseVariantColumnType
  finish?: BaseVariantColumnType
  edge?: BaseVariantColumnType
  substrateWeight?: BaseVariantColumnType
  size?: BaseVariantColumnType
  brand?: BaseVariantColumnType
  wrap?: BaseVariantColumnType
  sla?: BaseVariantColumnType
  orientation?: BaseVariantColumnType
  fulfilledFrom?: BaseVariantColumnType
}

export type VariantTableType = {
  columns: VariantColumnType
  rows: VariantRowType[]
}

export interface ProductCatalogueProductInterface {
  availability: string
  blankPhotographyAssets: { name: string; filename: string; urlSlug: string }[]
  breadcrumbs: { name: string; slug: string }[]
  careInstructions: string[]
  colourProfiles: ColourProfileInterface[]
  colours: { colours: ProductColourInterface[]; notes: string }
  devices: { brand: string; models: string[] }[]
  eco: ProductEcoCredentials
  externalLinks: { name: string; description: string; url: string }[]
  features: string[]
  finish: string[] | null
  frames: ProductFramesInterface[]
  global: boolean
  heroImage: string
  images: string[]
  imageSpec: ImageSpecInterface
  manufacturing: ProductManufacturingInterface
  materials: ProductMaterialsInterface
  mockups: boolean
  mounts: { name: string; description: string; colours: ProductColourInterface[] }[]
  name: string
  packaging: ProductPackaging[]
  photographyAssets: PhotographyAssetsInterface
  platforms: { name: string; image: string }[]
  pluralName: string
  pricing: Record<string, { source: 'fixed' | 'converted'; value: number } | undefined>
  printingMethod: string[] | null
  productSheet: string
  singularName: string
  sizes: ProductSizesInterface
  strapline: string
  templates: TemplateInterface[]
  texture: string | null
  urlSlug: string
  variants: VariantTableType
  weight: { gsm: string | null; ozyd: string | null }
  whiteness: string | null
}

export interface ColourProfileInterface {
  id: string
  name: string
  filename: string
  urlSlug: string
}

export interface TemplateInterface {
  name: string
  brand: string
  filename: string
  url: string
}

interface PhotographyAssetsInterface {
  assets: { name: string; thumbnail: string; download: string }[]
  downloadAll: string
}

interface ProductApiErrorResponseInterface {
  statusText?: string
  statusCode?: number
  data?: unknown
  message?: string
  traceParent?: string
}

export function useProductCatalogueProduct(slug: string) {
  const { data, error } = useSWR<
    ProductCatalogueProductInterface,
    FetchErrorInterface<ProductApiErrorResponseInterface>
  >(`${process.env.REACT_APP_PRODUCT_API}/catalogue/${slug}`, fetcher)

  return {
    catalogueProduct: data,
    isLoadingCatalogueProduct: !data && !error,
    catalogueProductFetchError: error
  }
}
