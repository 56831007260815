import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CellContext } from '@tanstack/react-table'

import Button from '../../Button'
import { VariantRowType } from '../../../hooks'
import { ROUTE_PATHS } from '../../../constants'
import { addBasketItem } from '../../../../actions/manualOrderForm'

export function VariantsOrderAction({ info }: { info: CellContext<VariantRowType, unknown> }) {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleAddToBasket = useCallback(
    (sku: string) => {
      dispatch(addBasketItem(sku))
      history.push(ROUTE_PATHS.MOF.BASKET)
      window.analytics.track('Add to basket from APC', { sku })
    },
    [dispatch, history]
  )

  return (
    <div className="flex justify-end">
      <Button size="sm" onClick={() => handleAddToBasket(info.row.original.sku)}>
        Order
      </Button>
    </div>
  )
}
