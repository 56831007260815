import clsx from 'clsx'
import useSWR, { SWRConfiguration } from 'swr'
import { useCallback, useEffect, useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

import SupportLink from '../../SupportLink'
import LoadingSpinner from '../../LoadingSpinner'
import { MockupViewInterface } from '../../../hooks'
import { mockup3DImageFetcher } from '../../../helpers'
import { FetchErrorInterface, StatusType } from '../../../interfaces'

export function Mockups3DViewDisplay({
  selectedMockupView,
  views3D,
  onSelectMockupView
}: {
  selectedMockupView: string | null
  views3D: Record<string, MockupViewInterface>
  onSelectMockupView: (view: string) => void
}) {
  useEffect(() => {
    if (!selectedMockupView) {
      onSelectMockupView(Object.keys(views3D)[0])
    }
  }, [selectedMockupView, onSelectMockupView, views3D])

  return (
    <div className="flex flex-wrap gap-4">
      {Object.entries(views3D).map(([viewKey, viewData]) => {
        if (!viewData.thumbnail_image) {
          return null
        }

        return (
          <button
            className={clsx(
              'min-h-[100px] min-w-[100px] cursor-pointer',
              selectedMockupView && selectedMockupView === viewKey ? 'opacity-100' : 'opacity-70'
            )}
            key={viewKey}
            onClick={() => onSelectMockupView(viewKey)}
          >
            <Thumbnail url={viewData.thumbnail_image} />
          </button>
        )
      })}
    </div>
  )
}

function Thumbnail({ url }: { url: string }) {
  const [status, setStatus] = useState<StatusType>('loading')
  const [errorCode, setErrorCode] = useState<number>()

  const handleSuccess = useCallback(() => {
    setStatus('success')
    setErrorCode(undefined)
  }, [])

  const handleError = useCallback((status: number | undefined) => {
    setErrorCode(status ?? 0)
    setStatus('error')
  }, [])

  if (status === 'loading') {
    return (
      <div className="flex h-[100px] w-[100px] items-center justify-center">
        <LoadingSpinner className="m-auto h-8 w-8" />
        <ThumbnailPoll url={url} onSuccess={handleSuccess} onError={handleError} />
      </div>
    )
  }

  if (status === 'success') {
    return <img className="h-[100px] w-[100px] object-contain" src={url} />
  }

  const code = 'TF-' + (errorCode?.toString() ?? '0')
  return (
    <div className="h-fit w-[100px] items-center justify-center text-sm text-white/70">
      <ExclamationCircleIcon className="mx-auto mb-4 h-10 w-10" />
      <p className="text-center">
        Please try again later or <SupportLink>contact us</SupportLink> if the issue persists (code {code})
      </p>
    </div>
  )
}

function ThumbnailPoll({
  url,
  onSuccess,
  onError
}: {
  url: string
  onSuccess: () => void
  onError: (status: number | undefined) => void
}) {
  const { data, error } = useThumbnail(url, {
    config: { refreshInterval: 2000, revalidateOnMount: false, revalidateOnFocus: false }
  })

  useEffect(() => {
    if (error) {
      onError(error.status)
    }
    if (data === 200) {
      onSuccess()
    }
  }, [data, error, onError, onSuccess])

  return null
}

function useThumbnail(url: string, { config = {} }: { config?: SWRConfiguration } = {}) {
  const { data, error } = useSWR<number, FetchErrorInterface<unknown>>(url, mockup3DImageFetcher, config)

  return { data, error }
}
