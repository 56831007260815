import { Link, useLocation } from 'react-router-dom'

import { formatCost } from '../../../helpers'
import { GlobalProductIcon } from '../../svg'
import { ColourSwatch } from './ColourSwatch.component'
import { CatalogueProductInterface, useUser } from '../../../hooks'

export function ProductCard({ productData }: { productData: CatalogueProductInterface }) {
  const { user } = useUser()
  const { pathname } = useLocation()

  const selectedCurrecy = user?.company.currencyCode.toLowerCase() ?? Object.keys(productData.pricing)[0].toLowerCase()
  const defaultCurrency =
    Object.entries(productData.pricing).find(([, pricingData]) => pricingData?.source === 'fixed')?.[0] ??
    Object.keys(productData.pricing)[0]
  const price = formatCost({
    amount: productData?.pricing?.[selectedCurrecy]?.value.toString(),
    currencyCode: selectedCurrecy
  })

  return (
    <Link
      className="flex flex-col gap-2 bg-white p-4 text-sm"
      to={`${pathname}/${productData.slug}`}
      onClick={() => {
        window.analytics.track('Clicked Product Card Tile', {
          productCategory: productData.name
        })
      }}
    >
      <div className="grid place-content-center bg-gray-100">
        <img
          className="h-[250px] w-[250px] object-contain object-center"
          alt={productData.name}
          src={productData.image}
        />
      </div>

      <h2 className="my-4 text-lg">{productData.name}</h2>

      <ColourSwatch details={productData} />

      <span>{productData.sizes}</span>
      <span>Fulfilled from {productData.manufacturingRegions.join(', ')}</span>

      <div className="mt-auto flex flex-row justify-between pt-4">
        {price && (
          <span
            className="cursor-help text-lg"
            title={
              defaultCurrency &&
              `* Prices exclude taxes and shipping. Non-${defaultCurrency.toUpperCase()} prices are approximate based on current rates.`
            }
          >
            From {price} *
          </span>
        )}
        {productData.global && (
          <div className="flex items-center justify-center gap-2 text-green-700">
            Ships worldwide <GlobalProductIcon className="h-7 w-7" />
          </div>
        )}
      </div>
    </Link>
  )
}
