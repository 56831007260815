import React from 'react'
import { Remark } from 'react-remark'

import { FooterContainer } from '.'
import { ProductCatalogueProductInterface, ProductFrameVariantInterface } from '../../../hooks'

export function Framing({ productData }: { productData: ProductCatalogueProductInterface }) {
  const frameDetails = productData.frames?.[0]
  const mountDetails = productData.mounts?.[0]
  const variantFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' })
  const variants = frameDetails.variants.reduce((acc: string[], variant) => {
    acc.push(variant.name.toLowerCase())
    return acc
  }, [])
  const schematicWidth = ['box', 'spacer', 'surface'].includes(frameDetails.name.toLowerCase()) ? '3/5' : '4/5'

  return (
    <FooterContainer>
      {productData.frames.length > 0 && (
        <div>
          <h3 className="mt-0">Framing</h3>
          <p className="mt-4">
            Our {frameDetails.name.toLowerCase()} frame is available in {variantFormatter.format(variants)}
          </p>

          <Variants variants={frameDetails.variants} />
        </div>
      )}

      {productData.frames.length > 0 && (
        <div className="flex-wrap md:flex">
          {frameDetails.schematic && (
            <div className="mt-12 text-center md:mr-12 md:text-left lg:mt-0">
              <div className={`mx-auto w-[${schematicWidth}] md:mx-0`}>
                <img className="max-h-48 md:max-h-56" src={frameDetails.schematic} />
              </div>
            </div>
          )}
          {frameDetails.dimensions.length > 0 && (
            <div>
              <table className="mx-auto mt-8 w-64 text-xs lg:mx-0 lg:mt-0 lg:text-sm">
                <tbody>
                  {frameDetails.dimensions.map((dimension) => (
                    <tr
                      key={dimension.label}
                      className="first-of-type:border-t first-of-type:border-gray-100 last-of-type:border-b last-of-type:border-gray-100"
                    >
                      <td className="align-to  whitespace-nowrap p-2">{dimension.label}</td>
                      <td className="p-2 text-right align-top">
                        <span>{dimension.valueInMM}</span>
                        <span>mm</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {productData.mounts.length > 0 && (
        <div>
          <h3 className="mt-0 mb-4">Mounts</h3>
          <Remark
            rehypeReactOptions={{
              components: {
                p(props: JSX.IntrinsicAttributes) {
                  return <React.Fragment {...props} />
                },
                a(props: JSX.IntrinsicAttributes) {
                  return <a {...props} className="w-fit text-purple-500" target="_blank" rel="noreferrer" />
                }
              }
            }}
          >
            {mountDetails.description}
          </Remark>
          <ul className="mt-8 flex flex-wrap">
            {mountDetails.colours.map((colour) => (
              <li className="mr-4" key={colour.name}>
                <div className="h-32 w-32" style={{ backgroundColor: colour.hex }} />
                <div className="mt-1 text-xs">{colour.name}</div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </FooterContainer>
  )
}

function Variants({ variants }: { variants: ProductFrameVariantInterface[] }) {
  if (!variants[0].description) {
    return (
      <ul className="mt-4 flex flex-wrap gap-16">
        {variants.map((variant) => (
          <li key={variant.name} className="flex flex-col items-center gap-4">
            <div className="relative">
              <img alt={variant.name} className="max-h-48 md:max-h-56" src={variant.chevron} />
            </div>
            <h4 className="text-base">{variant.name}</h4>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <ul className="mt-4 flex flex-col flex-wrap gap-16">
      {variants.map((variant) => (
        <FrameVariantWithDescription key={variant.name} variant={variant} />
      ))}
    </ul>
  )
}

function FrameVariantWithDescription({ variant }: { variant: ProductFrameVariantInterface }) {
  return (
    <li className="flex items-center gap-8">
      <div className="group relative">
        <img alt={variant.name} className="h-48 w-32 object-contain object-center md:h-56" src={variant.chevron} />
        {variant.swatch && (
          <img
            alt={variant.name}
            className="absolute right-4 bottom-4 h-8 w-8 rounded-full border-2 border-white shadow-md md:h-16 md:w-16 md:border-4 md:transition-all md:group-hover:h-24 md:group-hover:w-24"
            src={variant.swatch}
          />
        )}
      </div>
      <div>
        <h4 className="text-base">{variant.name}</h4>
        {variant.description && <p>{variant.description}</p>}
        <div className="mt-2 text-sm text-gray-600">
          {variant.tone && <p>Undertones: {variant.tone}</p>}
          {variant.decor && <p>Decor: {variant.decor}</p>}
        </div>
      </div>
    </li>
  )
}
